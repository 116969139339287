<template>
  <div>
<!--    <div v-if="datalist.title!=null">
      &lt;!&ndash;<van-row>
        <van-col span="22" class="mt3 mright mr10">
          <span class="txt">{{datainfo.mdfuser.nickName}}</span>
        </van-col>
        <van-col span="2" class="mt4 mr10">
          <van-image width="2rem" height="2rem" round :src="userinfo.headimgurl"></van-image>
        </van-col>
      </van-row>&ndash;&gt;
      <van-row>
        <van-col span="16" offset="1" class="mt4">{{datalist.title}}</van-col>
      </van-row>
      <div class="line"></div>
      <div v-for="(item,key) in datalist.groups" :key="key" class="mt20">
        <van-row>
          <van-col span="23" offset="1" class="mb10">{{item.title}}
            <span v-if="item.type == 'list'" class="ml15" @click="changeshow"><van-icon name="arrow-down" color="#e5e5e5" size="14"/></span>
          </van-col>
        </van-row>
          <van-row v-if="item.type == 'data'">
            <van-col :span="22" offset="1" class="mb11 ml6v">
              <div v-for="(items,keys) in item.fields" :key="keys">
              <van-row>
                <van-col :span="24"><span style="color: #7f7f7f;">{{items.title}}</span></van-col>
              </van-row>
              <van-row>
                <van-col :span="24" >
                  <van-cell-group>
                    <div v-if="items.type=='select'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''" :value="datainfo[items.name].label" />
                      <van-cell v-else  />
                    </div>
                    <div v-else-if="items.type=='mutiselect'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''" >
                        <template #default>
                          <span v-for="(i,ke) in datainfo[items.name]" :key="ke">{{i.label}}&nbsp;</span>
                        </template>
                      </van-cell>
                      <van-cell v-else />
                    </div>
                    <div v-else-if="items.type=='contacts'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"
                                :value="datainfo[items.name].nickName +' '+ datainfo[items.name].phoneNum">
                      </van-cell>
                      <van-cell v-else/>
                    </div>
                    <div v-else-if="items.type == 'img'||items.type == 'file'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"  >
                        <template #extra>
                          <van-image width="3rem" height="4rem" v-for="(it,k) in datainfo[items.name].list" :key="k" :src="it" class="mr5" @click="showimg(datainfo[items.name].list)">
                            <template v-slot:loading>
                              <van-loading type="spinner" size="16" />
                            </template>
                          </van-image>
                        </template>
                      </van-cell>
                      <van-cell v-else >
                        &lt;!&ndash;                     <template #extra>
                                               <van-image width="3rem" height="3rem" v-for="(it,k) in img" :key="k" :src="it" @click="showimg(img)">
                                                 <template v-slot:loading>
                                                   <van-loading type="spinner" size="16" />
                                                 </template>
                                               </van-image>
                                             </template>&ndash;&gt;
                      </van-cell>
                    </div>
                    <div v-else>
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''" :value="datainfo[items.name]"></van-cell>
                      <van-cell v-else  />
                    </div>
                  </van-cell-group>
                </van-col>
              </van-row>
              </div>
            </van-col>
          </van-row>
          <van-row v-if="item.type == 'list'&&show == true" class="mb11 ">
            <van-col span="22" offset="1" class="ml6v" v-for="(it,ke) in datainfo[item.name]" :key="ke">
              <div v-for="(items,keys) in item.fields" :key="keys">
              <span style="color: #7f7f7f;" class="mb7">{{ items.title }}：</span>
              <span v-if="items.type == 'mutiselect'">
                <span v-for="(i,k) in it[itms.name]" :key="k">{{ i.label }}</span>
              </span>
              <span v-else-if="items.type == 'select'">
                {{ it[items.name].label }}
&lt;!&ndash;                {{ it[items.name].label.nickName!=undefined?it[items.name].label.nickName:it[items.name].label}}&ndash;&gt;
              </span>
                <span v-else-if="items.type == 'contacts'">{{ it[items.name].nickName +' '+ it[items.name].phoneNum }}</span>
                <span v-else-if="items.type == 'img'">
                <van-image width="3rem" height="4rem" v-for="(itt,k) in it[items.name].list" :key="k" :src="itt" class="mr5" @click="showimg(it[items.name].list)">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="16" />
                  </template>
                </van-image>
              </span>
              <span v-else>{{ it[items.name] }}</span>
              </div>
              <div class="line1"></div>
            </van-col>
          </van-row>
        </div>
        <div class="line"></div>
    </div>-->
    <info-display :datalist="datalist" :datainfo="datainfo"></info-display>
  </div>
</template>

<script>
import qs from "qs";
import {get} from "@/utils/request";
import {Toast} from "vant";
import mixins from '@/mixins'
import infoDisplay from '@/components/infoDisplay'

export default {
  name: "Message",
  mixins: [mixins],
  data(){
    return{
      imgUrl:"http://bullpm-1253367923.cos.ap-chengdu.myqcloud.com",
      imgCompress:"?imageMogr2/thumbnail/!70p",
      companyId:"",
      img: [require('@/assets/sixu.png'),require('@/assets/10.jpg')],
      code: "",
      state: "",
      datalist:{},//获取的数据格式
      userinfo:{},
      datainfo:{},
    }
  },
  components: {
    infoDisplay,
  },
  created() {
    let flag = this.isWeixin();
    if (flag){
      this.getCode();
    }else {
      this.$router.push('notwx');
    }

    /*this.datalist = this.getForm();
    console.log("form",this.getForm());
    this.datainfo = {
      FiledStatus: "RUN",
      appId: "d7a685542b8b5110551315474aa9f16c",
      dataId: "6565896f8a8941ec8bd7fca18c552409",
      deptId: "35f1566fd9b346c88fa9457d3c35e8cc",
      field102: {label: "系统bug", value: "1"},
      field103: {label: "工作台", value: "0b8ae92ea375211bdb00000a"},
      field104: [{label: "pc端", value: "1"}, {label: "移动端", value: "2"}],
      field105: "项目筛选和排序一起筛选没有结果",
      field106: {label: "一般", value: "1"},
      field111: "2021-09-30 14:47",
      field112: "2021-09-23 14:40",
      field114: {label: "已解决", value: "1"},
      mAppId: "d7a685542b8b5110551315474aa9f16c",
      mdfuser: {nickName: "小荭帽", userId: "42916067cb234a9b9dd213b0c4911303"},
      root: 1,
      ts: 1634883965115,
      upDataId: "6565896f8a8941ec8bd7fca18c552409",
      _id: {$oid: "1168fde65084df223800008c",}
    };*/

  },
  methods:{
    getCode(){//获取code
      /*let href = "https://sixu.work/message?code=021z7Hll2uSSa84CeKnl27oY2L1z7HlL&state=4526"
      if (href.indexOf("state") != -1){
        this.code= qs.parse(href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(href.split("#")[0].split("?")[1]).state;
        console.log("code",this.code);
        console.log("state",this.state);
      }*/
      if (window.location.href.indexOf("state") != -1){
        this.code = qs.parse(window.location.href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(window.location.href.split("#")[0].split("?")[1]).state
      }
      if (this.code){
        //调用接口，获取用户信息,和数据列表信息
        get('/sso/auth/msg/'+this.code+'/'+this.state).then(res=>{
          if (res!=null&&res.data!=null&&res.data.content!=null){
            //console.log('contents',res);
            let contents = res.data.content;
            this.companyId = contents.form.companyId
            this.datalist = JSON.parse(contents.form.formDef);
            //console.log('form',this.datalist);
            if(contents.data!=null&&contents.data!=''){
              this.datainfo = contents.data;
            }
            for (let i in this.datalist.groups){
              let item = this.datalist.groups[i];
              for (let j in item.fields){
                let items = item.fields[j];
                if (items.type == 'img'){//当有img时，会组装img路径如下所示
                  if (this.datainfo[items.name]!=null&&this.datainfo[items.name]!=''){
                    this.datainfo[items.name].list = [];
                    for (let k in this.datainfo[items.name]){
                      let d = this.datainfo[items.name][k];
                      if (d.fileName!=null && d.fileName!=''){
                        this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName + this.imgCompress);
                      }
                    }
                  }
                }
                if (items.type == 'file'){
                  if (this.datainfo[items.name]!=null&&this.datainfo[items.name]!=''){
                    this.datainfo[items.name].list = [];
                    for (let k in this.datainfo[items.name]){
                      let d = this.datainfo[items.name][k];
                      if (d.fileName!=null&&d.fileName!=''){
                        this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName);
                      }
                    }
                  }
                }
              }
            }
          }else {
            Toast.fail(res.data.errorMsg);
          }
        }).catch(err=>err)
      }else {//
      }
    },
  }
}
</script>

<style scoped>
.font14{font-size: 14px;}
.col32{color: #323233;}
.mt1{margin-top: 5px;}
.mb1{margin-bottom: 5px;}
.mt3{margin-top: 3vh;}
.mb7{margin-bottom: 7px;}
.mt4{margin-top: 4vh;}
.mt5{margin-top: 5vh;}
.mt20{margin-top: 2vh;}
.ml6v{margin-left: 6vw;}
.ml15{margin-left: 15px;}
.mb20{margin-bottom: 2vh;}
.mt15{margin-top: 15px;}
.mb11{margin-bottom: 11px;}
.mb10{margin-bottom: 2vh;}
.line1{border-top: #f2f2f2 solid 1px;width: 92vw;margin: 7px auto;}
.line{border-top: #f2f2f2 solid 1px;width: 94vw;margin: 20px auto;}
.mr{margin-right: 18vw;}
.mr5{margin-right: 5px;}
.mr10{margin-right: 10px;}
.mright{text-align:right;}
.btn{text-align: center;margin: 4vh auto;}
.txt{vertical-align: middle;}
.van-cell{padding: 10px 0!important;}
</style>