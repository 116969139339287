import formdata from "@/assets/form.json"

const mixCommons = {
    data(){
        return{
            BASE_URL: process.env.VUE_APP_BASE_API,
        }
    },
    methods:{
        getForm(){
            return formdata;
        },
        //判断是否微信打开
        isWeixin(){
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        /*格式化月日*/
        formatDatas (m) {
            return m < 10 ? '0' + m : m
        },
        formatDate(date) {
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            return year + '-' + this.formatDatas(month) + '-' + this.formatDatas(day);
            //return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
        },
        //获取当前时间（时，分）
        formatTime(){
            let tim = new Date();
            let hours = this.formatDatas(tim.getHours());
            let min = this.formatDatas(tim.getMinutes());
            this.currentTime = hours+':'+min;
        },
        formatDateTime(val){
            let year = val.getFullYear();
            let month = val.getMonth() + 1;
            let day =val.getDate();
            let hour = val.getHours();
            let min = val.getMinutes();
            return year + '-' + this.formatDatas(month) + '-' + this.formatDatas(day) + ' ' + this.formatDatas(hour) + ':' + this.formatDatas(min);
            //return `${val.getFullYear()}/${val.getMonth() + 1}/${val.getDate()} ${val.getHours()}:${val.getMinutes()}`;
        },
    }
}

export default mixCommons